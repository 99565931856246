import * as Sentry from '@sentry/browser'

const NODE_ENV = process.env.NODE_ENV
const isDevelopment = NODE_ENV === 'development'
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

const tagKey = 'Auth UI'

export const logger = {

  error: (error, scopeTag) => {
    if (isDevelopment) {
      console.error(error)
    } else {
      Sentry.init({ dsn: SENTRY_DSN })
      if (scopeTag) {
        Sentry.withScope((scope) => {
          scope.setLevel('error')
          scope.setTag(tagKey, Object.values(scopeTag[1]))
        })
      }
      Sentry.captureException(error)
    }
  },

  warn: (warning) => {
    if (isDevelopment) {
      console.warn(warning)
    }
  },

  info: info => {
    if (isDevelopment) {
      console.log(info)
    }
  },

  dir: directory => {
    if (isDevelopment) {
      console.dir(directory)
    }
  }
}
